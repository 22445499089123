<template>
  <b-col xs="12" class="px-0">
    <b-alert :show="isShowErrors" dismissible variant="danger">{{
      $tc("ERROR_500")
    }}</b-alert>
    <b-row>
      <b-col
        cols="12"
        :md="isAdmin ? 6 : 12"
        justify="center"
        class="align-middle"
      >
        <label for="input-name" class="label-height "
          >{{ $tc("CAMPAIGNS_SETTINGS.CAMPAIGN_NAME") }}
          <span class="text-danger">*</span>
        </label>
        <b-form-input
          v-model="campaignName"
          id="input-campaign-name"
          required
          :state="checkCampaignName"
          placeholder="Campaign's name"
          class="form-control--grey"
        ></b-form-input>

        <b-form-invalid-feedback :state="checkCampaignName">
          {{ $tc("CAMPAIGNS_SETTINGS.FORM.ERROR_CAMPAIGN_NAME") }}
        </b-form-invalid-feedback>
      </b-col>

      <b-col
        v-if="isAdmin"
        cols="12"
        md="6"
        sm="12"
        justify="center"
        class="align-middle"
      >
        <label for="input-select-company" class="label-height "
          >{{ $tc("CAMPAIGNS_SETTINGS.COMPANY")
          }}<span class="text-danger">*</span></label
        >
        <b-form-select
          v-model="company"
          id="input-select-company"
          :options="companies"
          required
          :state="checkCompany"
          :disabled="isUpdating"
          text-field="name"
          value-field="id"
          class="form-control--grey"
        ></b-form-select>

        <b-form-invalid-feedback :state="checkCompany">
          {{ $tc("CAMPAIGNS_SETTINGS.FORM.ERROR_COMPANY") }}
        </b-form-invalid-feedback>
      </b-col>

      <b-col md="6" sm="12" justify="center">
        <label for="input-feed" class="label-height "
          >{{ $tc("CAMPAIGNS_SETTINGS.RSS_FEED")
          }}<span class="text-danger">*</span></label
        >
        <b-form-input
          v-model="feed"
          id="input-feed"
          :state="checkFeed"
          placeholder="Paste your RSS feed"
          class="form-control--grey"
        ></b-form-input>

        <b-form-invalid-feedback id="input-feed">
          {{ $tc("CAMPAIGNS_SETTINGS.FORM.ERROR_RSS_FEED") }}
        </b-form-invalid-feedback>
      </b-col>

      <b-col cols="12" md="6" sm="12" justify="center">
        <label for="input-budget" class="label-height "
          >{{ $tc("CAMPAIGNS_SETTINGS.BUDGET")
          }}<span class="text-danger">*</span>
        </label>

        <b-input-group append="€">
          <b-form-input
            v-model="budget"
            id="input-budget"
            :state="checkBudget"
            placeholder="Budget"
            type="number"
            class="form-control--grey"
          ></b-form-input>
        </b-input-group>

        <b-form-invalid-feedback :state="checkBudget">
          {{ $tc("CAMPAIGNS_SETTINGS.FORM.ERROR_BUDGET") }} {{ miniBudget }}€
        </b-form-invalid-feedback>
      </b-col>

      <b-col cols="12" md="6" sm="12" justify="center">
        <label for="input-cpl" class="label-height "
          >{{ $tc("CAMPAIGNS_SETTINGS.CPL") }}<span class="text-danger">*</span>
        </label>

        <b-input-group append="€">
          <b-form-input
            v-model="cpl"
            id="input-cpl"
            :state="checkCPL"
            placeholder="Cost per listens"
            type="number"
            class="form-control--grey"
          ></b-form-input>
        </b-input-group>

        <b-form-invalid-feedback :state="checkCPL">
          {{ $tc("CAMPAIGNS_SETTINGS.FORM.ERROR_CPL") }} {{ miniCPL }}€
        </b-form-invalid-feedback>
      </b-col>

      <b-col cols="12" md="6" sm="12" justify="center">
        <label for="input-advertiser" class="label-height"
          >Advertiser<span class="text-danger">*</span>
          <HelperTooltip
            icon="mdi-help-circle"
            :text="$tc('CAMPAIGNS_SETTINGS.TOOLTIPS.ADVERTISER')"
        /></label>

        <b-input-group class="form-control--grey w-100 ">
          <template #append>
            <div
              v-if="isLoadingAdvertisers"
              class="d-flex align-items-center p-2"
            >
              <b-spinner label="Spinning"></b-spinner>
            </div>
          </template>

          <b-form-input
            v-model="advertisersSelected.name"
            list="advertiser-list"
            class="form-control--grey"
            :state="checkAdvertiser"
            :disabled="isUpdating"
          ></b-form-input>
        </b-input-group>

        <b-form-invalid-feedback :state="checkAdvertiser">
          {{ $tc("CAMPAIGNS_SETTINGS.FORM.ERROR_ADVERTISER") }}
        </b-form-invalid-feedback>

        <datalist id="advertiser-list">
          <option
            v-for="(item, index) in itemsAdvertisers"
            :key="`advertiser_${index}`"
            @click="selectAdvertiser(item)"
            >{{ item.name }}</option
          >
        </datalist>
      </b-col>

      <b-col cols="12" md="6" sm="12" justify="center">
        <label for="input-autopromo" class="label-height"
          >Autopromo
          <HelperTooltip
            icon="mdi-help-circle"
            :text="$tc('CAMPAIGNS_SETTINGS.TOOLTIPS.AUTOPROMO')"
        /></label>

        <b-form-checkbox
          v-model="autopromo"
          switch
          size="lg"
          class="ml-2"
        ></b-form-checkbox>
      </b-col>

      <b-col cols="12" md="6" sm="12" justify="center">
        <label for="input-agency" class="label-height">Agency </label>

        <b-input-group class="form-control--grey w-100 ">
          <template #append>
            <div v-if="isLoadingAgencies" class="d-flex align-items-center p-2">
              <b-spinner label="Spinning"></b-spinner>
            </div>
          </template>

          <b-form-input
            v-model="agenciesSelected.name"
            list="agency-list"
            class="form-control--grey"
            :state="checkAgency"
            :disabled="isUpdating"
          ></b-form-input>
        </b-input-group>

        <b-form-invalid-feedback :state="checkAgency">
          {{ $tc("CAMPAIGNS_SETTINGS.FORM.ERROR_AGENCY") }}
        </b-form-invalid-feedback>

        <datalist id="agency-list">
          <option
            v-for="(item, index) in itemsAgencies"
            :key="`agency_${index}`"
            @click="selectAgency(item)"
            >{{ item.name }}</option
          >
        </datalist>
      </b-col>

      <div class="d-flex flex-row w-100 mt-5 justify-content-center">
        <div class="h-100 d-flex align-items-center border rounded px-10 py-2">
          <h5 class="font-weight-bold">
            {{ $tc("CAMPAIGNS_SETTINGS.LISTENS") }} {{ getVolumeListening }}
          </h5>
        </div>
      </div>

      <div class="container-next-step mr-2">
        <button
          class="btn btn-primary ml-3 button-height"
          @click="submitForm"
          :disabled="isDisabled"
        >
          <template v-if="!isLoading">
            {{ $tc("CAMPAIGNS_SETTINGS.FORM.SUBMIT") }}
          </template>
          <b-spinner v-if="isLoading" variant="white"></b-spinner>
        </button>
      </div>
    </b-row>
  </b-col>
</template>

<script>
import { getCompaniesV2 } from "@/api/companies/getters";
import HelperTooltip from "@/components/HelperTooltip";
import {
  createCampaignSettings,
  updateCampaignSetttings
} from "@/api/campaigns/manage/setters";
import { getListAdvertisers } from "@/api/campaigns/advertisers/getters";
import { getListAgencies } from "@/api/campaigns/agencies/getters";

export default {
  props: {
    isAdmin: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    campaign: {
      type: Object,
      required: false
    }
  },

  components: {
    HelperTooltip
  },

  data() {
    return {
      campaignName: "",
      company: "",
      companies: [],
      budget: "",
      feed: "",
      cpl: "1",
      type: "native",
      miniBudget: 500,
      miniCPL: 0.2,
      isCanCheck: false,
      isLoading: false,
      isValidFluxRSS: null,
      isShowErrors: false,
      advertiser: "",
      isLoadingAdvertisers: false,
      advertisersSelected: {
        name: ""
      },
      itemsAdvertisers: [],
      agency: "",
      isLoadingAgencies: false,
      agenciesSelected: {
        name: ""
      },
      itemsAgencies: [],
      autopromo: false
    };
  },

  computed: {
    getVolumeListening() {
      const volumeListening = Math.floor(this.budget / this.cpl);
      return isFinite(volumeListening) ? volumeListening : 0;
    },

    checkCampaignName() {
      return this.isCanCheck ? this.campaignName.length > 2 : null;
    },

    checkCompany() {
      const isCompany = this.company ? true : false;
      return this.isCanCheck ? isCompany : null;
    },

    checkFeed() {
      const regexURL = /^(https):\/\/[^\s$.?#].[^\s]*$/;
      return (
        (this.isCanCheck ? regexURL.test(this.feed) : null) &&
        this.isValidFluxRSS
      );
    },

    checkBudget() {
      return this.isCanCheck ? this.budget >= this.miniBudget : null;
    },

    checkCPL() {
      return this.isCanCheck ? this.cpl >= this.miniCPL : null;
    },

    checkAdvertiser() {
      let checkAdvertiser = this.isCanCheck
        ? this.advertisersSelected.name.length > 0
        : null;

      // For the campaign create before advertiser is required
      if (this.isUpdating) {
        checkAdvertiser = true;
      }

      return checkAdvertiser;
    },

    checkAgency() {
      let checkAgency = this.isCanCheck
        ? this.agenciesSelected.name.length > 0
        : null;

      // For the campaign create before agency is required
      if (this.isUpdating) {
        checkAgency = true;
      }

      return checkAgency;
    },

    hasError() {
      return (
        !this.checkCampaignName ||
        !this.checkCompany ||
        !this.checkFeed ||
        !this.checkBudget ||
        !this.checkCPL ||
        !this.checkAdvertiser
      );
    },

    isUpdating() {
      return this.$route.params.id ? true : false;
    },

    isDisabled() {
      return this.isCanCheck && this.isLoading;
    }
  },

  beforeMount() {
    this.loadCompanies();
  },

  methods: {
    async loadCompanies() {
      try {
        const response = await getCompaniesV2(this.axios);
        this.companies = response.data;

        this.defaultCompany();
      } catch (error) {
        console.error("---ERROR-COMPANIES---");
        console.error(error);
      }
    },

    async loadAdvertisers(campaignId) {
      this.isLoadingAdvertisers = true;

      try {
        const advertisers = await getListAdvertisers(campaignId, this.axios);
        this.itemsAdvertisers = advertisers;
      } catch (error) {
        console.error("---ERROR-ADVERTISERS---");
        console.error(error);
      }

      this.isLoadingAdvertisers = false;
    },

    async loadAgencies(campaignId) {
      this.isLoadingAgencies = true;

      try {
        const agencies = await getListAgencies(campaignId, this.axios);
        this.itemsAgencies = agencies;
      } catch (error) {
        console.error("---ERROR-AGENCIES---");
        console.error(error);
      }

      this.isLoadingAgencies = false;
    },

    defaultCompany() {
      if (this.user.company && !this.campaign.company) {
        const userCompany = this.companies.find(
          el => el.id === this.user.company.id
        );

        if (userCompany) {
          this.company = userCompany.id;
        }
      }
    },

    async submitForm() {
      this.isCanCheck = true;
      this.isValidFluxRSS = true;

      if (!this.hasError) {
        const company = this.companies.find(
          element => element.id === this.company
        );

        let campaign = {
          name: this.campaignName,
          companyId: company.id,
          rssFeed: this.feed,
          budget: this.budget,
          native: this.native,
          cpl: this.cpl,
          type: "audio_ads",
          advertiser: this.advertisersSelected.name,
          agency: this.agenciesSelected.name,
          autopromo: this.autopromo
        };

        try {
          this.isLoading = true;

          if (this.campaign.id) {
            campaign = await updateCampaignSetttings(
              campaign,
              this.campaign.id,
              this.axios
            );

            campaign.tags = this.campaign.tags;
          } else {
            campaign = await createCampaignSettings(campaign, this.axios);
            campaign.tags = [];
          }
          this.$emit("submitForm", campaign);
        } catch (error) {
          this.isShowErrors = true;
        }

        this.isLoading = false;
      }
    },

    selectAdvertiser(item) {
      this.advertiser = item.name;
      this.advertisersSelected = item;
    },

    selectAgency(item) {
      this.agency = item.name;
      this.agenciesSelected = item;
    }
  },

  watch: {
    campaign() {
      if (this.campaign.company) {
        this.campaignName = this.campaign.name;
        this.company = this.campaign.company.id;
        this.feed = this.campaign.program.url;
        this.budget = this.campaign.budget;
        this.cpl = this.campaign.cpl;
        this.advertisersSelected = this.campaign.advertiser
          ? this.campaign.advertiser
          : { name: "" };
        this.agenciesSelected = this.campaign.agency
          ? this.campaign.agency
          : { name: "" };
        this.autopromo = this.campaign.autopromo;
      }
    },

    company() {
      this.loadAdvertisers(this.company);
      this.loadAgencies(this.company);
    }
  }
};
</script>

<style scoped>
.container-next-step {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.button-height {
  height: 42px;
}

.container-combobox {
  margin-top: 38.39px;
}

.label-height {
  height: 24px;
}
</style>
